import React from 'react';
import Box from '@webAtoms/Box/Box';
import FeaturedBuilds from '@webSections/FeaturedBuilds';
import { FeaturedBuild as OFeaturedBuild } from '@webOrganisms/FeaturedBuild/FeaturedBuild';
import UpNext from '@webMolecules/UpNext/UpNext';
import FeaturedDetailed from '@localTypes/FeaturedDetailed';
import * as Route from '@routes';
import EnquireForm from '@webSections/EnquireForm';
import Container from '@webAtoms/Container/Container';
import * as styles from './contactUs.module.scss';
import Grid from '@webAtoms/Grid/Grid';
import { GatsbyImage, IGatsbyImageData } from 'gatsby-plugin-image';
import Button from '@webAtoms/Button/Button';
import { useCalendlyLinks } from '@helpers/useCalendlyLinks';
import PageHeading from '@webMolecules/PageHeading/PageHeading';

export interface ContactUsProps {
  body: string;
  image?: IGatsbyImageData;
  buttons: { label: string; url: string }[];
}

const ContactUs: React.FC<ContactUsProps> = ({ body, buttons, image }) => {
  const buttonsRef = React.useRef<HTMLDivElement>(null);
  useCalendlyLinks(buttonsRef);

  return (
    <Container>
      <PageHeading
        className={styles.pageHeading}
        title="Contact us"
        children={
          <div className={styles.buttons} ref={buttonsRef}>
            {buttons.map((b, i) => (
              <Box key={i}>
                <Button
                  title={b.label}
                  href={b.url}
                  target="_blank"
                  intent="secondary"
                  icon="arrowRight"
                >
                  {b.label}
                </Button>
              </Box>
            ))}
          </div>
        }
      />
      <Box className={styles.contactUsLayout}>
        <EnquireForm className={styles.enquireForm} body={body} />

        <div className={styles.imageContainer}>
          {image && (
            <GatsbyImage loading="lazy" image={image} alt="Contact us" />
          )}
        </div>
      </Box>
      <UpNext
        links={[
          { label: 'Our approach', href: Route.ourApproach() },
          { label: 'Modular homes', href: Route.designs() },
        ]}
      />
    </Container>
  );
};

export default ContactUs;
