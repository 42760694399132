import React from 'react';
import { RefObject } from 'react';
import { openPopupWidget } from 'react-calendly';

export const useCalendlyLinks = (ref: RefObject<HTMLElement>) => {
  React.useEffect(() => {
    if (ref.current) {
      const calendlyUrlHrefs = ref.current.querySelectorAll(
        `[href*="https://calendly.com/"]`
      );
      calendlyUrlHrefs.forEach(el => {
        el.addEventListener('click', ev => {
          const url = el.getAttribute('href');
          if (url) {
            ev.preventDefault();
            openPopupWidget({ url });
            return false;
          }
        });
      });
    }
  }, [ref]);
};
