import * as React from 'react';
import { graphql, PageProps } from 'gatsby';
import { PageContactUsQuery } from '@generated/GraphqlTypes';
import Base from '@webLayouts/Base/Base';
import ContactUs from '@webPages/ContactUs/ContactUs';
import { getImage, ImageDataLike } from 'gatsby-plugin-image';

const ContactUsPage: React.FC<PageProps<PageContactUsQuery>> = ({ data }) => {
  const title = data?.contactUs?.frontmatter?.title ?? '';
  const seoTitle = data?.contactUs?.frontmatter?.seoTitle || title || undefined;
  const seoDescription =
    data?.contactUs?.frontmatter?.seoDescription ||
    data?.contactUs?.excerpt ||
    undefined;

  const body = data?.contactUs?.html ?? '';

  const image = getImage(
    data?.contactUs?.frontmatter?.heroImage as ImageDataLike
  );
  const buttons = (data?.contactUs?.frontmatter?.buttons ?? []).map(a => ({
    label: a?.label ?? '',
    url: a?.url ?? '',
  }));

  return (
    <Base theme="light" title={seoTitle} description={seoDescription}>
      <ContactUs body={body} image={image} buttons={buttons} />
    </Base>
  );
};

export default ContactUsPage;

export const query = graphql`
  query PageContactUs {
    contactUs: markdownRemark(
      fields: { collection: { eq: "pages" } }
      frontmatter: { page: { eq: "contact-us" } }
    ) {
      html
      excerpt
      frontmatter {
        title
        seoTitle
        seoDescription

        heroImage {
          publicURL
          childImageSharp {
            gatsbyImageData(
              width: 2000
              # placeholder: BLURRED
              # quality: 70
              # formats: [AUTO, WEBP, AVIF, JPG]
            )
          }
        }

        buttons {
          label
          url
        }
      }
    }
  }
`;
